import React, { useEffect, useState } from 'react';
import GeneralPartWarehouseEntrance from './GeneralPartWarehouseEntrance';
import ProductTable from './ProductTable';
import Modal from '../../../App/Components/Modal/Modal';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import Button from '../../../App/Components/Button/Button';
import EventAddForm from './warehouseEntryProduct/EventAddForm';
import { generalWarehouseEntryV } from '../../../App/Validation/WarehouseEntryValidation';

const WarehouseEntryForm = ({ handleClose, matInputDocID = 0, onSubmit }) => {
  const { getWarehouseEntryForm, warehouseEntryForm, getWarehouseEntryDocNum } =
    useWarehouseEntry();
  const [showProductForm, setShowProductForm] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const onCloseProductForm = () => {
    setShowProductForm((prevState) => !prevState);
  };

  useEffect(() => {
    getWarehouseEntryForm(matInputDocID);
  }, [matInputDocID]);

  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...warehouseEntryForm?.matInput },
    validationSchema: generalWarehouseEntryV,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
    },
  });

  const commonProps = {
    warehouseEntryForm,
    getWarehouseEntryDocNum,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    handleClose,
  };
  const isDisabledProductForm =
    values.docTypeID === 2 ? values.inputTypeName : values.storeName;

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-warehouse-entry-form"
    >
      <GeneralPartWarehouseEntrance {...commonProps} />
      <div className="L-product-add-btn">
        <Button
          disabled={!isDisabledProductForm}
          customClass={isDisabledProductForm ? ' ' : 'G-disabled-button'}
          onClick={() => setShowProductForm(true)}
        />
      </div>
      <ProductTable
        productData={warehouseEntryForm?.inputs}
        setDataChanged={setDataChanged}
      />

      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        executeDisabled={!dirty || !isValid}
      />

      {showProductForm && (
        <EventAddForm
          handleClose={onCloseProductForm}
          warehouseEntryForm={warehouseEntryForm}
        />
      )}
    </Modal>
  );
};

export default WarehouseEntryForm;
