import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import Button from '../../../App/Components/Button/Button';
import useMakeAnAddition from '../../hooks/useMakeAnAddition';
import { dateFormat, removeCommas } from '../../../App/Utilities/Utilities';

import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
const MakeAnAdditionTable = ({ mainData }) => {
  const { saveNewMakeAnAddition } = useMakeAnAddition();
  const [newMakeAnAddition, setNewMakeAnAddition] = useState(mainData?.makes);
  const [isDisabled, setIsDisabled] = useState(false);
  const [checkSign, setCheckSign] = useState(false);
  useEffect(() => {
    setNewMakeAnAddition(mainData?.makes);
  }, [mainData?.makes]);

  useEffect(() => {
    const checkEveryItem = newMakeAnAddition?.every((item) => item.sign);
    setCheckSign(checkEveryItem);
  }, [newMakeAnAddition]);

  const handleChangeHours = (e, row) => {
    const { value } = e.target;
    const newHours = Number(value);
    if (row.formulaID === 5) {
      const updatedRows = newMakeAnAddition?.map((r) =>
        r.empNo === row.empNo ? { ...r, hours: newHours } : r,
      );
      setNewMakeAnAddition(updatedRows);
      return;
    }

    const updatedRows = newMakeAnAddition?.map((r) => {
      if (r.empNo === row.empNo) {
        let newAmount = 0;

        if (r.formulaID === 2 && r.formSign === 2) {
          newAmount = r.salary * newHours * r.coeff;
        } else if (r.workHours > 0) {
          newAmount = (r.salary / r.workHours) * newHours * r.coeff;
        }
        setIsDisabled(false);
        return {
          ...r,
          hours: newHours,
          amount: Math.round(newAmount),
          isModifed: true,
        };
      } else {
        return r;
      }
    });

    setNewMakeAnAddition(updatedRows);
  };

  const handleChangeCoefficient = (e, row) => {
    const { value } = e.target;
    const newCoefficient = value && parseFloat(value);
    if (row.formulaID === 5) return;
    const updatedRows = newMakeAnAddition?.map((r) => {
      if (r.empNo === row.empNo) {
        let newAmount = 0;

        if (r.formulaID === 2 && r.formSign === 2) {
          newAmount = r.salary * r.hours * newCoefficient;
        } else if (r.workHours > 0) {
          newAmount = (r.salary / r.workHours) * r.hours * newCoefficient;
        }
        setIsDisabled(false);
        return {
          ...r,
          coeff: newCoefficient,
          amount: Math.round(newAmount),
          isModifed: true,
          sign: false,
        };
      } else {
        return r;
      }
    });

    setNewMakeAnAddition(updatedRows);
  };

  const onAmountChange = (e, targetRow) => {
    const { value } = e.target;
    const newAmount = removeCommas(value);
    if (targetRow.formulaID === 5) return;
    const updatedRows = newMakeAnAddition?.map((row) => {
      if (targetRow.empNo === row.empNo) {
        const updatedRow = {
          ...row,
          isModifed: true,
          amount: newAmount,
          sign: true,
        };
        setIsDisabled(false);
        return updatedRow;
      }
      return row;
    });

    setNewMakeAnAddition(updatedRows);
  };

  const onMarkAllCheckbox = (e) => {
    const { checked } = e.target;
    setIsDisabled(false);

    if (checked) {
      const checkAll = newMakeAnAddition.map((item) => {
        let sign = true;
        if (item.formulaID === 1) {
          if (item.formSign === 2) {
            item.amount = item.salary * item.workHours * item.coeff;
          } else {
            item.amount = item.salary * item.coeff;
          }
        } else if (item.formulaID === 2) {
          if (item.formSign === 2) {
            item.amount = Math.round(item.salary * item.hours * item.coeff, 2);
          } else {
            if (item.workHours > 0) {
              item.amount = Math.round(
                (item.salary / item.workHours) * item.hours * item.coeff,
                2,
              );
            } else {
              item.amount = 0;
            }
          }
        } else if (item.formulaID === 4) {
          if (item.formSign === 2) {
            item.amount = Math.round(
              item.hgAmount * item.hours * item.coeff,
              2,
            );
          } else {
            if (item.workHours > 0) {
              item.amount = Math.round(
                (item.hgAmount / item.workHours) * item.hours * item.coeff,
                2,
              );
            } else {
              item.amount = 0;
            }
          }
        } else if (item.formulaID === 3) {
          item.amount = item.hgAmount * item.coeff;
        }

        return { ...item, sign, isModifed: true };
      });

      setNewMakeAnAddition(checkAll);
    } else {
      const checkAllWithoutSign = newMakeAnAddition.map((item) => {
        return {
          ...item,
          sign: false,
          isModifed: true,
          amount: 0,
          hours: 0,
        };
      });
      setNewMakeAnAddition(checkAllWithoutSign);
    }
  };

  const onMarkCurrentCheckbox = (e, row) => {
    const { checked } = e.target;
    setIsDisabled(false);

    if (row.formulaID === 5 && checked) {
      const updatedRows = newMakeAnAddition?.map((r) =>
        r.empNo === row.empNo ? { ...r, sign: true } : r,
      );
      setNewMakeAnAddition(updatedRows);
      return;
    }

    let updatedList = newMakeAnAddition.map((item) => {
      if (item.empNo === row.empNo) {
        if (checked) {
          let sign = true;
          let amount = 0;

          if (item.formulaID === 1) {
            amount =
              item.formSign === 2
                ? item.salary * item.workHours * item.coeff
                : item.salary * item.coeff;
          } else if (item.formulaID === 2) {
            if (item.formSign === 2) {
              amount = Math.round(item.salary * item.hours * item.coeff, 2);
            } else {
              amount =
                item.workHours > 0
                  ? Math.round(
                      (item.salary / item.workHours) * item.hours * item.coeff,
                      2,
                    )
                  : 0;
            }
          } else if (item.formulaID === 3) {
            amount = item.hgAmount * item.coeff;
          }

          return { ...item, sign, amount, isModifed: true };
        } else {
          return {
            ...item,
            sign: false,
            isModifed: true,
            amount: 0,
            hours: 0,
            coeff: 0,
          };
        }
      } else {
        return item;
      }
    });

    setNewMakeAnAddition(updatedList);
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 550 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.emplName,
      customStyle: { maxWidth: 520, overflow: 'hidden' },
    },
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.calcDate),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Ժամ',
      cell: (row) => (
        <input
          type="number"
          value={row?.hours}
          onChange={(e) => handleChangeHours(e, row)}
          disabled={!mainData?.hourEditSign}
          maxLength={4}
        />
      ),
      isEdit: mainData?.hourEditSign,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Գործակիցը',
      cell: (row) => (
        <input
          type="number"
          value={row?.coeff}
          onChange={(e) => handleChangeCoefficient(e, row)}
          disabled={!mainData?.coeffEditSign}
          maxLength={5}
          pattern="[0-9.,]*"
        />
      ),
      isEdit: mainData?.coeffEditSign,

      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Գումար',
      cell: (row) => (
        <NumericFormatFiled
          value={row.amount}
          allowNegative={true}
          name="amount"
          changeHandler={(e) => onAmountChange(e, row)}
          inputTitle=""
          disabled={!mainData?.amountEditSign}
        />
      ),
      isEdit: mainData?.amountEditSign,
      customStyle: { maxWidth: 150 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox
            onChange={(e) => onMarkAllCheckbox(e)}
            checked={checkSign}
          />
        </span>
      ),

      cell: (row) => (
        <Checkbox
          checked={row.hours > 0 || row.amount ? true : row.sign}
          value={row.hours > 0 || row.amount ? true : row.sign}
          onChange={(e) => onMarkCurrentCheckbox(e, row)}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];
  const onSaveHandler = async () => {
    try {
      await saveNewMakeAnAddition(newMakeAnAddition);
      setIsDisabled(true);
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };

  return (
    <>
      {newMakeAnAddition !== null && newMakeAnAddition?.length ? (
        <Table
          customClass="L-make-an-addition-table G-table-has-scroll"
          data={newMakeAnAddition}
          columnConfig={columnConfig}
        />
      ) : null}
      {newMakeAnAddition !== null && newMakeAnAddition?.length && (
        <div className="G-confirm-btn-wrap">
          <Button
            onClick={onSaveHandler}
            text="Հաստատել"
            type="submit"
            disabled={isDisabled}
            customClass={isDisabled && 'G-disabled-button'}
          />
        </div>
      )}
    </>
  );
};

export default MakeAnAdditionTable;
