import React, { useEffect, useState } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import EntryOperationExecutionTable from './EntryOperationExecutionTable';
import EntryOperationExecutionData from './EntryOperationExecutionData';
import useWarehouseEntryOperations from '../../hooks/useWarehouseEntryOperations';
import './style.scss';
import OperationIncomeTaxForm from './OperationIncomeTaxForm';
import Button from '../../../App/Components/Button/Button';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { dataTimeFormat } from '../../../App/Utilities/Utilities';

const EntryOrderOperationExecution = ({ matInputDocID, handleClose }) => {
  const {
    getEntryOperationExecution,
    entryOperationExecution,
    saveEntryOpExecution,
  } = useWarehouseEntryOperations();
  const [showIncomeTaxForm, setShowIncomeTaxForm] = useState(false);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    setFormData(entryOperationExecution?.matInput);
  }, [entryOperationExecution?.matInput]);

  useEffect(() => getEntryOperationExecution(matInputDocID), [matInputDocID]);

  const onAddHandler = async () => {
    let updatedEntries = entryOperationExecution?.entries.map((entry) => ({
      ...entry,
      docDate: dataTimeFormat(formData.docDate),
    }));
    try {
      await saveEntryOpExecution(updatedEntries, handleClose);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-entryOrder-operation-execution-form"
    >
      <EntryOperationExecutionData
        formData={formData}
        setFormData={setFormData}
      />
      <div className="L-incomeTax-btn">
        <Button
          text="Եկամտային հարկ"
          //disabled={disabled}
          //customClass
          onClick={() => setShowIncomeTaxForm(true)}
        />
      </div>
      <EntryOperationExecutionTable
        operationExecutionData={entryOperationExecution?.entries}
      />

      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={onAddHandler}
        // executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        // executeDisabled={!dirty || !isValid}
      />
      {showIncomeTaxForm && (
        <OperationIncomeTaxForm
          handleClose={() => setShowIncomeTaxForm(false)}
          matInputDocID={matInputDocID}
        />
      )}
    </Modal>
  );
};

export default EntryOrderOperationExecution;
