import * as Yup from 'yup';
import { SelectFieldMessage } from './ErrorMessage';
import { removeCommas } from '../Utilities/Utilities';

const requiredMessage = (field) => `${field} չի կարող լինել դատարկ`;
const positiveMessage = (field) => `${field} պետք է լինի 0-ից մեծ`;

export const warehouseEntryProductV = () =>
  Yup.object().shape({
    amount: Yup.number()
      .required(requiredMessage('Քանակը'))
      .nullable()
      .test('non-zero', 'Քանակը չպետք է լինի 0', (value) => {
        const numericValue = removeCommas(value);
        return numericValue > 0;
      }),
    purPrice: Yup.string()
      .required(requiredMessage('Գումարը'))
      .test('positive-number', positiveMessage('Գումարը'), (value) => {
        const numericValue = removeCommas(value);
        return numericValue > 0;
      }),

    matValueName: Yup.string()
      .required(requiredMessage('Նյութի անունը'))
      .nullable(),
    accCode: Yup.string()
      .required(requiredMessage('Հաշվարկային կոդը'))
      .nullable(),
  });

export const generalWarehouseEntryV = () =>
  Yup.object().shape({
    docNum: Yup.string().required('Document number is required.'),
    docDate: Yup.date().required('Document date is required.').nullable(),
    inputTypeName: Yup.string().when('docTypeID', {
      is: 2,
      then: Yup.string().required(SelectFieldMessage).nullable(),
      otherwise: Yup.string().nullable(),
    }),
    storeName: Yup.string().when('docTypeID', {
      is: 2,
      then: Yup.string().required(SelectFieldMessage).nullable(),
      otherwise: Yup.string().nullable(),
    }),
    partnerAccCode: Yup.string().when('docTypeID', {
      is: 2,
      then: Yup.string().required(SelectFieldMessage).nullable(),
      otherwise: Yup.string().nullable(),
    }),

    partnerName: Yup.string().when(['docTypeID', 'ident'], {
      is: (docTypeID, ident) => docTypeID === 2 && ident === true,
      then: Yup.string().required(SelectFieldMessage).nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });
