import { removeCommas } from '../../../../App/Utilities/Utilities';

export const updatePriceAndTax = (values, inputTypeId, setFieldValue) => {
  const { purPrice, amount, incomeSign, incomeTax: userDefinedTax } = values;
  const parsedPurPrice = removeCommas(purPrice);
  const parsedAmount = removeCommas(amount);

  let incomeTax = userDefinedTax || 0;
  let price = 0;
  let totalPrice = 0;

  if (incomeSign) {
    if (inputTypeId === 3) {
      incomeTax = Math.round(parsedPurPrice - parsedPurPrice / 1.2, 2);
    } else if (inputTypeId === 4) {
      incomeTax = Math.round(parsedPurPrice - parsedPurPrice / 1.1, 2);
    }
  } else {
    if (inputTypeId === 3) {
      incomeTax = Math.round((parsedPurPrice * 20) / 100, 2);
    } else if (inputTypeId === 4) {
      incomeTax = Math.round((parsedPurPrice * 10) / 100, 2);
    }
  }

  price = parsedPurPrice + incomeTax;
  totalPrice = parsedAmount * price;

  setFieldValue('incomeTax', incomeTax);
  setFieldValue('price', price);
  setFieldValue('totalPrice', totalPrice);
};

// import { removeCommas } from '../../../../App/Utilities/Utilities';

// export const updatePriceAndTax = (values, inputTypeId, setFieldValue) => {
//   const { purPrice, amount, incomeSign, incomeTax: userDefinedTax, price: userDefinedPrice } = values;
//   const parsedPurPrice = removeCommas(purPrice);
//   const parsedAmount = removeCommas(amount);

//   let incomeTax = userDefinedTax  0; // Allow user-defined tax if provided
//   let price = userDefinedPrice || 0; // Allow user-defined price if provided
//   let totalPrice = 0;

//   // Calculate income tax if not provided by user
//   if (!userDefinedTax) {
//     if (incomeSign) {
//       if (inputTypeId === 3) {
//         incomeTax = Math.round(parsedPurPrice - parsedPurPrice / 1.2, 2);
//       } else if (inputTypeId === 4) {
//         incomeTax = Math.round(parsedPurPrice - parsedPurPrice / 1.1, 2);
//       }
//     } else {
//       if (inputTypeId === 3) {
//         incomeTax = Math.round((parsedPurPrice * 20) / 100, 2);
//       } else if (inputTypeId === 4) {
//         incomeTax = Math.round((parsedPurPrice * 10) / 100, 2);
//       }
//     }
//   }

//   // Calculate price if not provided by user
//   if (!userDefinedPrice) {
//     price = parsedPurPrice + incomeTax;
//   }

//   // Calculate total price
//   totalPrice = parsedAmount * price;

//   // Update field values
//   setFieldValue('incomeTax', incomeTax);
//   setFieldValue('price', price);
//   setFieldValue('totalPrice', totalPrice);
// };
