import React, { useRef } from 'react';
import InputTitle from './InputTitle';
import './input.scss';

const Input = (props) => {
  const {
    onChange,
    value,
    type,
    name,
    requiredFiled,
    inputTitle = '',
    className = '',
    onBlur,
    lang,
    disabled,
    inputClassName,
    ...restProps
  } = props;
  const inputRef = useRef(null);

  // const handleInputChange = (event) => {

  //     onChange(event.target.value);

  // };

  return (
    <div className={`${className} G-s-input-wrap`}>
      <InputTitle requiredFiled={requiredFiled} inputTitle={inputTitle} />
      <input
        className={`${inputClassName} G-s-input`}
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        lang={lang}
        autoComplete="off"
        disabled={disabled}
        onInput={(e) => {
          e.target.value = e.target.value.replace(',', '.');
        }}
        {...restProps}
      />
    </div>
  );
};

export default Input;
