import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';

export const fetchEntryOrderOperations = async (
  currentCompanyID,
  matInputDocID,
) => {
  try {
    const response = await getRequest(
      `GetMatInputEntries?companyID=${currentCompanyID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const removeEntryOrderOperation = async (entryID) => {
  try {
    const response = await deleteRequest(`deleteMatInputEntry/${entryID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting entryID:', error);
    throw error;
  }
};
// create service for entryOrderOperationExecution

export const fetchEntryOperationExecution = async (
  currentCompanyID,
  matInputDocID,
) => {
  try {
    const response = await getRequest(
      `getMatInputForEntry?companyID=${currentCompanyID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const addEntryOpExecution = async (newEnter) => {
  try {
    const response = await postRequest('saveMatInputEntries', newEnter);
    return response.data;
  } catch (error) {
    console.error('Error adding newEnter:', error);
    throw error;
  }
};

//for Income Tax
export const fetchEntryIncomeTax = async (currentCompanyID, matInputDocID) => {
  try {
    const response = await getRequest(
      `GetMatInputIncomeEntry?companyID=${currentCompanyID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addEntryIncomeTax = async (newIncomeTax) => {
  try {
    const response = await postRequest('saveMatInputIncomeEntry', {
      ...newIncomeTax,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding newIncomeTax:', error);
    throw error;
  }
};
