import React, { useEffect, useState } from 'react';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { GeneralVacationFields } from './FieldsConfig';
import Input from '../../Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import DateComponent from '../../components/dateComponent/DateComponent';
import { dataTimeFormat, dateFormat } from '../../../App/Utilities/Utilities';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import InputTitle from '../../Input/InputTitle';

const GeneralVacationForm = ({
  handleClose,
  handleChange,
  touched,
  setFieldValue,
  values,
  handleBlur,
  errors,
  handleSubmit,
  dirty,
  isValid,
  generalVacationFormData,
  getVacationEndDate,
}) => {
  const [isDisConfirm, setIsDisConfirm] = useState(false);
  const [vacationDateMess, setVacationDateMess] = useState('');
  const onClose = () => setVacationDateMess(false);

  let dropDownOptions = [];

  useEffect(() => {
    setFieldValue('amount', Math.round(values.vacDays * values.dailyAvg, 2));
  }, [values.dailyAvg, values.vacDays]);

  const updateFieldValueAndDropdown = async (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const { empNo, vacStartDate, vacDays } = values || {};
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
    //drop-i change jamanak el er ashxatum hanel tvecin chgidem xi dra hamar em comment are
    // if (dropdownId) {
    //   const res = await getVacationEndDate({
    //     empNo,
    //     vacStartDate,
    //     vacDays,
    //     stNo: dropdownItemId,
    //   });
    //   setVacationDateMess(res?.message);
    //   setFieldValue('vacEndDate', res?.vacationEndDate);
    // }
  };

  const onChangeDate = async (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      return;
    }

    const formattedDate = dataTimeFormat(date);
    setFieldValue(name, formattedDate);
    const { empNo, vacDays, stNo } = values || {};
    if (name === 'vacStartDate') {
      const res = await getVacationEndDate({
        empNo,
        vacStartDate: date,
        vacDays,
        stNo,
      });
      setVacationDateMess(res?.message);
      setFieldValue('vacEndDate', res?.vacationEndDate);
    }
  };

  const onCustomChange = async (e) => {
    const { name, value } = e.target;
    const { empNo, vacStartDate, stNo } = values || {};
    setFieldValue(name, value);
    if (name === 'vacDays' && value) {
      const res = await getVacationEndDate({
        empNo,
        vacStartDate,
        vacDays: value,
        stNo,
      });
      setVacationDateMess(res?.message);
      setFieldValue('vacEndDate', res?.vacationEndDate);
    } else {
      handleChange(e);
    }
  };
  const onConfirmClick = () => {
    setIsDisConfirm(false);
    onClose();
  };
  const onCancelClick = () => {
    setIsDisConfirm(true);
    onClose();
  };

  return (
    <div className="L-general-vacation-form">
      <form onSubmit={handleSubmit}>
        {GeneralVacationFields?.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            dateName,
            dateTitle,
            maxLength,
            maxDate,
            dateFields,
            disabled,
          } = field;

          const fieldError = touched[name] && errors[name];

          if (typeof Children === 'function' && generalVacationFormData) {
            dropDownOptions = Children(generalVacationFormData[optionName]);
          }

          switch (fieldType) {
            case 'input':
              return (
                <div key={`input-${name}`} className="L-general-vacation-filed">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    onChange={onCustomChange}
                    value={values[name]}
                    maxLength={maxLength}
                    disabled={disabled}
                  />
                </div>
              );
            case 'amount':
              return (
                <div key={name} className="L-general-vacation-filed">
                  <NumericFormatFiled
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    changeHandler={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                  {fieldError && <ErrorFiled error={fieldError} />}
                </div>
              );
            case 'dropdown':
              return (
                <div
                  key={`dropdown-${fieldName}`}
                  className="L-general-vacation-filed"
                >
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                  />
                  {required && !values[fieldName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );
            case 'vacationDate':
              return (
                <div className="L-vacation-date-wrap">
                  <InputTitle requiredField={required} inputTitle={dateTitle} />
                  <div className="employees-radio-flex G-flex">
                    {dateFields.map(({ dateName, dateTitle }) => (
                      <DateComponent
                        key={dateName}
                        value={dateFormat(values[dateName] || '')}
                        onChange={(date) => onChangeDate(date, dateName)}
                        name={dateName}
                        title={dateTitle}
                        requiredFiled={false}
                        maxDate={maxDate}
                        disabled={disabled}
                        openToDate={new Date(values[dateName])}
                      />
                    ))}
                  </div>
                </div>
              );

            case 'date':
              return (
                <div
                  className="L-general-vacation-filed"
                  key={`date-${dateName}`}
                >
                  <DateComponent
                    value={dateFormat(values[dateName] || '')}
                    onChange={(date) => onChangeDate(date, dateName)}
                    name={dateName}
                    title={dateTitle}
                    requiredFiled={required}
                    maxDate={maxDate}
                    disabled={disabled}
                    openToDate={new Date(values[dateName])}
                  />
                  {required && !values[dateName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );
            default:
              return null;
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={
            !dirty || !isValid || isDisConfirm ? 'G-disabled-button' : ''
          }
          executeDisabled={!dirty || !isValid || isDisConfirm}
        />
      </form>

      {vacationDateMess?.length > 0 && (
        <AcceptOrCancelModal
          modalContent={vacationDateMess + 'Ցանկանում եք շարունակել'}
          closeModal={onCancelClick}
          confirmClick={onConfirmClick}
        />
      )}
    </div>
  );
};

export default GeneralVacationForm;
