import React from 'react';
import EventEditForm from './EventEditForm';
import EventRemove from './EventRemove';
import EntryOrderOperations from '../entryOrderOperations/EntryOrderOperations';
import EntryOrderOperationExecution from '../entryOrderOperationExecution/EntryOrderOperationExecution';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'delete':
      return (
        <EventRemove
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );
    case 'edit':
      return (
        <EventEditForm
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );

    case 'operations':
      return (
        <EntryOrderOperations
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );

    case 'operationExecution':
      return (
        <EntryOrderOperationExecution
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );

    default:
      return null;
  }
};

export default Forms;
