import React from 'react';
import AcceptOrCancelModal from '../../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deleteProductAction } from '../../../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';
import { useDispatch } from 'react-redux';

const EventRemove = ({ matInputID, handleClose }) => {
  const dispatch = useDispatch();
  const onRemoveHandler = async () => {
    dispatch(deleteProductAction(matInputID));
    handleClose();
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
    </>
  );
};

export default EventRemove;
