import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Loading from '../../../App/Components/Loading/Loading';
import checkIcon from '../../assets/icons/check.svg';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../../App/Utilities/Utilities';
import Action from '../../../App/Components/Table/Action';
import { warehouseEntryActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
import Tooltip from '../../../App/Components/Tooltip/Tooltip';

const WarehouseEntryTable = ({ warehouseEntry, isLoading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Պահեստը',
      cell: (row) => row.storeName,
      customStyle: { maxWidth: 450, overflow: 'hidden' },
    },
    {
      title: 'Փաստ. N',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Փաստ. տեսակը',
      cell: (row) => row.docTypeName,
      customStyle: { maxWidth: 200, overflow: 'hidden' },
    },
    {
      title: 'Ձեռք բերման տեսակը',
      cell: (row) => row.inputTypeName,
      customStyle: { maxWidth: 270, overflow: 'hidden' },
    },
    {
      title: 'ՀՀ տարածք/Ներմուծում',
      cell: (row) => row.supplyName,
      customStyle: { maxWidth: 160, overflow: 'hidden' },
    },
    {
      title: 'Մատակարարը',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 250, overflow: 'hidden' },
    },

    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 120 },
    },

    {
      title: <Tooltip content="Հաշվապահական թղթակցություն">Հաշվ./թղթ.</Tooltip>,

      cell: (row) =>
        row.sign ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 70 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={warehouseEntryActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {warehouseEntry !== null && warehouseEntry && warehouseEntry?.length ? (
        <Table
          customClass=""
          data={warehouseEntry}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default WarehouseEntryTable;
