import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';
import Table from '../../../App/Components/Table/Table';
import Loading from '../../../App/Components/Loading/Loading';
import checkIcon from '../../assets/icons/check.svg';
const EntryOperationExecutionTable = ({
  operationExecutionData,
  isLoading,
}) => {
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accCode,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 190 },
    },

    {
      title: 'Ձևակերպված',
      cell: (row) =>
        row.sign ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 100 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {operationExecutionData !== null &&
      operationExecutionData &&
      operationExecutionData?.length ? (
        <Table
          customClass="L-entry-operation-execution-table"
          data={operationExecutionData}
          columnConfig={columnConfig}
        />
      ) : null}
    </>
  );
};

export default EntryOperationExecutionTable;
