import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import './style.scss';
import useWarehouseEntryOperations from '../../hooks/useWarehouseEntryOperations';
import EntryOrderOperationTable from './EntryOrderOperationTable';

const EntryOrderOperations = ({ handleClose, matInputDocID }) => {
  const { getEntryOrderOperations, entryOperationData, isLoading } =
    useWarehouseEntryOperations();
  useEffect(() => getEntryOrderOperations(matInputDocID), [matInputDocID]);

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-entry-order-operations-form"
    >
      <EntryOrderOperationTable
        entriesOperation={entryOperationData}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default EntryOrderOperations;
