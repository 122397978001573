import React, { useState } from 'react';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';
import Table from '../../../App/Components/Table/Table';
import Loading from '../../../App/Components/Loading/Loading';
import RemoveEntryOperation from './RemoveEntryOperation';
const EntryOrderOperationTable = ({ entriesOperation, isLoading }) => {
  const [showRemoveForm, setShowRemoveForm] = useState(null);
  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Պահեստը',
      cell: (row) => row.storeName,
      customStyle: { maxWidth: 350, overflow: 'hidden' },
    },
    {
      title: 'Մատակարարը',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 250, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 120 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => setShowRemoveForm(row.entryID)}
        >
          Ջնջել
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {entriesOperation !== null &&
      entriesOperation &&
      entriesOperation?.length ? (
        <Table
          customClass=""
          data={entriesOperation}
          columnConfig={columnConfig}
        />
      ) : null}
      {showRemoveForm && (
        <RemoveEntryOperation
          handleClose={() => setShowRemoveForm(false)}
          entryID={showRemoveForm}
        />
      )}
    </>
  );
};

export default EntryOrderOperationTable;
