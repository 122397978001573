import React, { useEffect, useState } from 'react';
import { warehouseEntryFormFields } from './FieldsConfig';
import Input from '../../components/Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import DateComponent from '../../components/dateComponent/DateComponent';
import { dataTimeFormat, dateFormat } from '../../../App/Utilities/Utilities';
import InputTitle from '../../components/Input/InputTitle';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';
import AddNew from '../../../App/Elements/Add/AddNew';
import NewItemForms from './NewItemForms';
import { useDispatch } from 'react-redux';
import { getWarehouseEntranceID } from '../../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';

const GeneralPartWarehouseEntrance = ({
  handleChange,
  touched,
  setFieldValue,
  values,
  handleBlur,
  errors,
  warehouseEntryForm,
  getWarehouseEntryDocNum,
}) => {
  const [openNewItemForm, setOpenNewItemForm] = useState(false);
  let dropDownOptions = [];
  const dispatch = useDispatch();
  const updateDocNum = async (storeNo, date) => {
    const year = new Date(date).getFullYear();
    const docNum = await getWarehouseEntryDocNum({ storeNo, year });
    setFieldValue('docNum', docNum);
  };
  useEffect(() => {
    if (values.storeNo && values.docDate) {
      updateDocNum(values.storeNo, values.docDate);
    }
  }, [values.storeNo, values.docDate]);

  const onDropdownChange = async (selectedOption, dropdownId, fieldName) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: '',
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;
    const ident = selectedOption?.ident;
    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);

    if (fieldName === 'partnerAccCode') {
      setFieldValue('ident', ident || '');
      if (!ident) {
        setFieldValue('partnerName', '');
        setFieldValue('partnerID', 0);
      }
    } else if (fieldName === 'inputTypeName') {
      setFieldValue('inputTypeId', dropdownItemId);
      dispatch(getWarehouseEntranceID({ inputTypeId: dropdownItemId }));
    } else if (fieldName === 'storeName') {
      await updateDocNum(dropdownItemId, values.docDate);
    }
  };

  const mainClassName = 'G-warehouse-entrance-wrap';

  const onChangeDate = async (date, name) => {
    const formattedDate = dataTimeFormat(date);
    setFieldValue(name, formattedDate);

    await updateDocNum(values.storeNo, date);
  };

  const handleRadioChange = (name, value) => {
    setFieldValue(name, value);
    dispatch(getWarehouseEntranceID({ docTypeID: value }));
    if (value === 1) {
      const resetFields = [
        'inputTypeName',
        'partnerAccCode',
        'accountName',
        'partnerName',
      ];

      resetFields.forEach((field) => setFieldValue(field, ''));
      setFieldValue('docDate', warehouseEntryForm?.remainderDate);
      setFieldValue('partnerID', 0);
    } else {
      setFieldValue('docDate', warehouseEntryForm?.matInput?.docDate);
    }
  };

  return (
    <div className="L-general-part-warehouse-entrance">
      {warehouseEntryFormFields?.map((field, index) => {
        const {
          fieldType,
          fieldName,
          label,
          name,
          required,
          Children,
          optionName,
          dropdownTitle,
          type,
          dropdownId,
          disabled,
          radioBtn,
          radioTitle,
          requiredRadioBtn,
          maxLength,
          addNewItem,
        } = field;
        const uniqueKey = fieldName || name || `field-${index}`;
        const fieldError = touched[name] && errors[name];
        const isRequired =
          typeof required === 'function' ? required(values) : required;

        if (typeof Children === 'function') {
          if (warehouseEntryForm) {
            dropDownOptions = Children(warehouseEntryForm[optionName]);
          }
        }

        switch (fieldType) {
          case 'input':
            return (
              <div key={uniqueKey} className={mainClassName}>
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredField={required}
                  onChange={handleChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  disabled={disabled}
                  maxLength={maxLength}
                  //error={}
                />
              </div>
            );
          case 'radio':
            return (
              <div key={uniqueKey} className={`${mainClassName} `}>
                <InputTitle
                  requiredField={requiredRadioBtn}
                  inputTitle={radioTitle}
                />
                <div className="radio-btn-flex">
                  {radioBtn?.map(({ value, name, label }, i) => (
                    <div
                      key={`${name}-${value}-${i}`}
                      className="G-flex radio-wrap"
                    >
                      <RadioButton
                        name={name}
                        value={value}
                        checked={value === values[name]}
                        onChange={() => handleRadioChange(name, value)}
                        label={label}
                        disabled={warehouseEntryForm?.inputs.length >= 1}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          case 'date':
            return (
              <div key={uniqueKey} className={mainClassName}>
                <DateComponent
                  value={dateFormat(values[field.dateName] || '')}
                  onChange={(date) => onChangeDate(date, field.dateName)}
                  name={field.dateName}
                  title={field.dateTitle}
                  requiredFiled={field.required}
                  openToDate={new Date(values[field.dateName] || new Date())}
                />
              </div>
            );

          case 'dropdown':
            return (
              <div
                key={uniqueKey}
                className={`${mainClassName}  G-dropdown-flex-block`}
              >
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={isRequired}
                  value={{
                    label: values[fieldName] || '',
                    value: values[fieldName] || '',
                  }}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    onDropdownChange(selectedOption, dropdownId, fieldName)
                  }
                  disabled={!isRequired}
                />
                {required && !values[fieldName] && (
                  <ErrorFiled error={fieldError} />
                )}
                {addNewItem && (
                  <AddNew
                    onClick={() => setOpenNewItemForm(fieldName)}
                    content={dropdownTitle}
                  />
                )}
              </div>
            );
          default:
            return null;
        }
      })}
      {openNewItemForm && (
        <NewItemForms
          formType={openNewItemForm}
          closeModal={setOpenNewItemForm}
          setFieldValue={setFieldValue}
        />
      )}
    </div>
  );
};

export default GeneralPartWarehouseEntrance;
