import React, { useEffect, useRef, useState } from 'react';
import Action from '../../../App/Components/Table/Action';
import { Commas, tableActionTitle } from '../../../App/Utilities/Utilities';
import { globalActionItems } from '../../Config/tableConfig';
import Loading from '../../../App/Components/Loading/Loading';
import Table from '../../../App/Components/Table/Table';
import ProdActionForms from './warehouseEntryProduct/ProdActionForms';
import ProductTotal from './ProductTotal';
import { hasDataChanged } from './dataComparison';

const ProductTable = ({ productData, isLoading, setDataChanged }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const initialProductData = useRef(null);

  useEffect(() => {
    if (!initialProductData.current && productData?.length) {
      initialProductData.current = productData.map((item) => ({ ...item }));
    }
    if (initialProductData.current) {
      const hasChanged = hasDataChanged(
        initialProductData.current,
        productData,
      );

      setDataChanged(hasChanged);
    }
  }, [productData]);

  useEffect(() => {
    const calculateTotalPrice = () => {
      const total = productData?.reduce(
        (sum, item) => sum + (item.totalPrice || 0),
        0,
      );
      setTotalPrice(total);
    };
    calculateTotalPrice();
  }, [productData]);

  const columnConfig = [
    {
      title: 'ՆԱ Կոդը',
      cell: (row) => row.matValueNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'ՆԱ անվանումը',
      cell: (row) => row.matValueName,
      customStyle: { maxWidth: 280, overflow: 'hidden' },
    },

    {
      title: 'Չ/Մ',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 100, overflow: 'hidden' },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գնման գինը',
      cell: (row) => Commas(row.purPrice),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'ԵԿ. հարկ',
      cell: (row) => Commas(row.incomeTax),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գինը',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => row.accCode,
      customStyle: { maxWidth: 100, overflow: 'hidden' },
    },
    {
      title: 'Հաստատված',
      cell: (row) => row.sign,
      customStyle: { maxWidth: 90 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {productData !== null && productData && productData?.length ? (
        <Table customClass="" data={productData} columnConfig={columnConfig} />
      ) : null}
      <ProductTotal total={totalPrice} />
      <ProdActionForms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default ProductTable;
