import { createAction } from '../../actionCreators';
import {
  ADD_WARE_HOUSE_PRODUCT,
  DELETE_WARE_HOUSE_ENTRY,
  DELETE_WARE_HOUSE_PRODUCT,
  FETCH_WARE_HOUSE_ENTRY,
  FETCH_WARE_HOUSE_FORM_ENTRY,
  GET_WARE_HOUSE_ENTRANCE_ID,
  UPDATE_WARE_HOUSE_DROP_DATA,
  UPDATE_WARE_HOUSE_ENTRY,
  UPDATE_WARE_HOUSE_PRODUCT,
} from './constants';

export const getWareHouseEntryAction = createAction(FETCH_WARE_HOUSE_ENTRY);
export const getWareHouseEntryFormAction = createAction(
  FETCH_WARE_HOUSE_FORM_ENTRY,
);
export const updateWareHouseEntryAction = createAction(UPDATE_WARE_HOUSE_ENTRY);
export const removeWareHouseEntryAction = createAction(DELETE_WARE_HOUSE_ENTRY);

export const addNewProductAction = createAction(ADD_WARE_HOUSE_PRODUCT);

export const editProductAction = createAction(UPDATE_WARE_HOUSE_PRODUCT);

export const deleteProductAction = createAction(DELETE_WARE_HOUSE_PRODUCT);

export const getWarehouseEntranceID = createAction(GET_WARE_HOUSE_ENTRANCE_ID);
export const addNewWarehouseDropData = createAction(
  UPDATE_WARE_HOUSE_DROP_DATA,
);
