import React, { useEffect, useState } from 'react';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';
import Header from '../../../App/Components/Header/Header';
import './style.scss';
import EventAddForm from './EventAddForm';
import WarehouseEntryFilter from './WarehouseEntryFilter';
import WarehouseEntryTable from './WarehouseEntryTable';
import {
  dataTimeFormat,
  simplifyObject,
} from '../../../App/Utilities/Utilities';
import Pagination from '../../../App/Components/Pagination/Pagination';
import Button from '../../../App/Components/Button/Button';

const WarehouseEntry = () => {
  const [showAddForm, setShowAddForm] = useState(false);

  const [query, setQuery] = useState({
    pageNumber: 0,
  });

  const {
    getWarehouseEntry,
    wareHouseEntryData,
    isLoading,
    getWarehouseEntryExcel,
    getWarehouseTimePeriod,
  } = useWarehouseEntry();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getWarehouseTimePeriod();

        const updatedQuery = {
          ...query,
          startDate: res.dateStart,
          endDate: res.dateEnd,
        };

        setQuery(updatedQuery);

        const modifyObj = simplifyObject({
          ...updatedQuery,
          startDate: dataTimeFormat(res.dateStart),
          endDate: dataTimeFormat(res.dateEnd),
        });

        await getWarehouseEntry(modifyObj);
      } catch (error) {
        console.error('Error in fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  const handlePageChange = (newPageNumber) => {
    setQuery((prevState) => ({
      ...prevState,
      pageNumber: newPageNumber,
    }));
  };

  const onSearch = () => {
    const modifyObj = simplifyObject({
      ...query,
      startDate: dataTimeFormat(query.startDate),
      endDate: dataTimeFormat(query.endDate),
    });
    getWarehouseEntry(modifyObj);
  };

  return (
    <>
      <Header pageTitle="Պահեստի մուտքի օրդեր" handelClick={onClose} />

      <div className="L-E-Invoicing-btn">
        <Button
          text="Մուտք E - invoicing -ից"
          //disabled={disabled}
          // onClick={() => setShowProductForm(true)}
        />
      </div>
      <WarehouseEntryFilter
        query={query}
        setQuery={setQuery}
        wareHouseEntryData={wareHouseEntryData}
        getExcelData={() =>
          getWarehouseEntryExcel(
            simplifyObject({
              ...query,
              startDate: dataTimeFormat(query.startDate),
              endDate: dataTimeFormat(query.endDate),
            }),
          )
        }
        onSearch={onSearch}
      />
      <WarehouseEntryTable
        warehouseEntry={wareHouseEntryData?.matInputDocs}
        isLoading={isLoading}
      />
      <Pagination
        pageCount={wareHouseEntryData?.pageCount?.pageCount}
        setPageNumber={handlePageChange}
        currentPage={query.pageNumber}
      />
      {showAddForm && <EventAddForm handleClose={onClose} />}
    </>
  );
};

export default WarehouseEntry;
