import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import DateComponent from '../../components/dateComponent/DateComponent';
import SelectAccount from '../../../App/Components/SelectBox/SelectAccount';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import { useFormik } from 'formik';
import useWarehouseEntryOperations from '../../hooks/useWarehouseEntryOperations';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import {
  dataTimeFormat,
  dateFormat,
  removeCommas,
} from '../../../App/Utilities/Utilities';
import { useSelector } from 'react-redux';
const OperationIncomeTaxForm = ({ handleClose, matInputDocID }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { getEntryIncomeTax, entryIncomeTaxData, saveEntryIncomeTax } =
    useWarehouseEntryOperations();
  useEffect(() => getEntryIncomeTax(matInputDocID), [matInputDocID]);

  const mainClassName = 'L-operation-incomeTax-wrap';
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...entryIncomeTaxData },
    //validationSchema: OperationsValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const handleChangeAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('creditAccountCode', values.value);
  };

  const onSubmit = async () => {
    const modifiedData = {
      companyID: currentCompanyID,
      matInputDocID: matInputDocID,
      docDate: dataTimeFormat(values.docDate),
      creditAccountCode: values.creditAccountCode,
      partnerAccCode: values.partnerAccCode,
      amount: removeCommas(values.amount),
    };
    try {
      await saveEntryIncomeTax(modifiedData, handleClose);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Modal closeHandler={handleClose} customClass="L-operation-incomeTax-form">
      <DateComponent
        requiredFiled={true}
        value={dateFormat(values.docDate || '')}
        onChange={(date) => setFieldValue('docDate', date)}
        name="docDate"
        title="Ամսաթիվը"
        openToDate={new Date(values.docDate || new Date())}
      />
      <div className={`${mainClassName} G-flex-align-center`}>
        <p className="G-title-p">Դեբետ հաշիվը</p>
        <span style={{ marginLeft: '10px', fontSize: '12px' }}>
          {values?.partnerAccCode}
        </span>
      </div>
      <div className={mainClassName}>
        <SelectAccount
          customClass=""
          title="Կրեդիտ հաշիվը"
          accountData={entryIncomeTaxData.creditAccounts}
          accountVal={{
            label: values.creditAccountCode,
            value: values.creditAccountCode,
          }}
          selectChangeAccount={(values) => handleChangeAccount(values)}
        />
      </div>

      <div className={mainClassName}>
        <NumericFormatFiled
          value={values.amount}
          allowNegative={true}
          name="amount"
          inputTitle="Գումարը"
          onBlur={handleBlur}
          changeHandler={handleChange}
          requiredFiled={true}
          //  disabled={!exchangeRateChange}
        />
      </div>
      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        executeDisabled={!dirty || !isValid}
      />
    </Modal>
  );
};

export default OperationIncomeTaxForm;
