import React from 'react';
import DateComponent from '../../components/dateComponent/DateComponent';
import { dateFormat } from '../../../App/Utilities/Utilities';

const EntryOperationExecutionData = ({ formData, setFormData }) => {
  const {
    supplyName,
    docTypeName,
    inputTypeName,
    storeName,
    partnerName,
    docNum,
    partnerAccCode,
    incomeTaxAmount,
    docDate,
  } = formData || {};

  const executionDataArr = [
    { name: 'Մատակարարվել է', value: supplyName },
    { name: 'Փաստաթղթի տեսակը', value: docTypeName },
    { name: 'Ձեռք բերման տեսակը', value: inputTypeName },
    { name: 'Պահեստ', value: storeName },
    { name: 'Մատակարար', value: partnerName },
    { name: 'Փաստաթուղթ N', value: docNum },
    { name: 'Կրեդիտ հաշի', value: partnerAccCode },
    { name: 'Եկամտային հարկի գումարը', value: incomeTaxAmount },
    { name: 'Ամսաթիվ', value: docDate, type: 'date' },
  ];

  const onChangeDate = (key, date) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: date,
    }));
  };

  return (
    <div className="L-entry-execution-container">
      <ul className="list">
        {executionDataArr.map((item, index) => (
          <li key={index} className="G-flex list-item">
            <strong className="label">{item.name} </strong>
            {item?.type === 'date' ? (
              <DateComponent
                value={dateFormat(formData?.docDate || '')}
                onChange={(date) => onChangeDate('docDate', date)}
                name="docDate"
                title=""
                openToDate={new Date(item.value || new Date())}
              />
            ) : (
              <span className="value">{item.value || ''}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EntryOperationExecutionData;
