export const hasDataChanged = (initialData, currentData) => {
  if (!initialData || !currentData) return false;
  if (initialData.length !== currentData.length) return true;

  return initialData.some((item, index) => {
    const initialItem = JSON.stringify(item);
    const currentItem = JSON.stringify(currentData[index]);
    return initialItem !== currentItem;
  });
};
