import React from 'react';
import { useDispatch } from 'react-redux';
import ProductForm from './ProductForm';
import { randomID, removeCommas } from '../../../../App/Utilities/Utilities';
import { addNewProductAction } from '../../../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';

const EventAddForm = ({ handleClose, params }) => {
  const dispatch = useDispatch();

  const handleAddProduct = (values, resetForm) => {
    const formattedValues = {
      ...values,
      purPrice: removeCommas(values.purPrice),
      totalPrice: values.amount * removeCommas(values.purPrice),
      price: removeCommas(values.price),
      sign: false,
      editing: false,
      matInputID: randomID(),
    };

    dispatch(addNewProductAction(formattedValues));
    resetForm();
    handleClose();
  };

  return <ProductForm onSubmit={handleAddProduct} handleClose={handleClose} />;
};

export default EventAddForm;
