import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../../App/Components/Modal/Modal';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import AddNew from '../../../../App/Elements/Add/AddNew';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import MatValueNameForm from '../../materialValuesNames/EventAddForm';
import useWarehouseEntry from '../../../hooks/useWarehouseEntry';
import { updatePriceAndTax } from './updatePriceAndTax';
import Input from '../../../components/Input/Input';
import Checkbox from '../../../../App/Components/Checkbox/Checkbox';
import NumericFormatFiled from '../../../../App/Components/NumericFormat/NumericFormatFiled';
import { fieldConfigs } from './fieldConfigs';
import { warehouseEntryProductV } from '../../../../App/Validation/WarehouseEntryValidation';
const ProductForm = ({ handleClose, initValues = {}, onSubmit }) => {
  const [openNewMathNameForm, setOpenNewMathNameForm] = useState(false);
  const { warehouseEntryForm, warehouseEntranceID } = useWarehouseEntry();
  const { inputTypeId, docTypeID } = warehouseEntranceID || {};
  const mainClassName = 'L-warehouseEntry-produce-filed';
  let dropDownOptions = [];
  const formik = useFormik({
    initialValues: { ...initValues },
    validationSchema: warehouseEntryProductV,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const handleSelectChange = (fieldName, dropdownId, selectedOption) => {
    const {
      value = '',
      id = '',
      unit = '',
      accCode = '',
    } = selectedOption || {};
    setFieldValue(fieldName, value);

    const resetFieldsMap = {
      matValueName: ['matValueNo', 'unit', 'accCode'],
    };

    if (resetFieldsMap[fieldName]) {
      resetFieldsMap[fieldName].forEach((resetField) =>
        setFieldValue(resetField, ''),
      );
    }

    if (fieldName === 'matValueName' && selectedOption) {
      setFieldValue('matValueNo', id);
      setFieldValue('unit', unit);
      setFieldValue('accCode', accCode);
    }
  };

  useEffect(() => {
    if (values) {
      updatePriceAndTax(values, inputTypeId, setFieldValue);
    }
  }, [inputTypeId, values]);

  const fieldList = fieldConfigs(docTypeID, inputTypeId);

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-warehouseEntry-produce-form"
    >
      <form onSubmit={handleSubmit}>
        {fieldList.map((field, index) => {
          const {
            fieldType,
            fieldName,
            title,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            maxLength,
            incomeSignArr,
            addNewItem,
            disabled,
          } = field;
          const isRequired =
            typeof required === 'function' ? required(values) : required;
          const isLength =
            typeof maxLength === 'function' ? maxLength(values) : maxLength;

          if (typeof Children === 'function' && warehouseEntryForm) {
            dropDownOptions = Children(warehouseEntryForm[optionName]);
          }
          switch (fieldType) {
            case 'input':
              return (
                <div key={index} className={mainClassName}>
                  <Input
                    type={type}
                    inputTitle={title}
                    name={name}
                    requiredField={isRequired}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={isLength}
                    onBlur={handleBlur}
                  />
                </div>
              );
            case 'amount':
              return (
                <div key={name} className={mainClassName}>
                  <NumericFormatFiled
                    inputTitle={title}
                    name={name}
                    requiredFiled={required}
                    changeHandler={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div
                  key={index}
                  className={`${mainClassName} ${
                    addNewItem ? 'G-dropdown-flex-block' : ''
                  }`}
                >
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={isRequired}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    onChange={(selectedOption) =>
                      handleSelectChange(fieldName, dropdownId, selectedOption)
                    }
                    options={dropDownOptions || []}
                    onBlur={handleBlur}
                  />
                  {addNewItem && (
                    <AddNew
                      onClick={() => setOpenNewMathNameForm(fieldName)}
                      content={dropdownTitle}
                    />
                  )}
                </div>
              );
            case 'incomeSignArr':
              return (
                <div key={index} className="L-income-sign-flex">
                  {incomeSignArr?.map(
                    ({ fieldType, name, title, label, required }, idx) => {
                      if (fieldType === 'amount') {
                        return (
                          <NumericFormatFiled
                            key={idx}
                            inputTitle={title}
                            name={name}
                            requiredFiled={required}
                            changeHandler={handleChange}
                            value={values[name]}
                            onBlur={handleBlur}
                          />
                        );
                      }
                      if (fieldType === 'checkbox') {
                        return (
                          <Checkbox
                            key={idx}
                            name={name}
                            label={label}
                            value={values[name]}
                            checked={values[name]}
                            onChange={() => setFieldValue(name, !values[name])}
                            onBlur={handleBlur}
                          />
                        );
                      }
                      return null;
                    },
                  )}
                </div>
              );

            default:
              return null;
          }
        })}
      </form>
      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        executeDisabled={!dirty || !isValid}
      />

      {openNewMathNameForm && (
        <MatValueNameForm
          formIsUsedExternally
          setFieldValue={setFieldValue}
          handleClose={() => setOpenNewMathNameForm(false)}
        />
      )}
    </Modal>
  );
};

export default ProductForm;
